<template>
  <div class="static-page accessibility-page">
    <HeaderClean :header="$t('accessibility.title')"/>

    <v-container class="padding-tb-50">
      <div v-for="section in $t('accessibility.sections')" :key="section.title">
        <vue-markdown :anchorAttributes="{target: '_blank'}">{{section.title}}</vue-markdown>
        <vue-markdown :anchorAttributes="{target: '_blank'}">{{section.text}}</vue-markdown>
      </div>

      <div class="accessibility-page-menu">
        <v-divider class="margin-tb-30"/>
        <h4 class="text-center font-20">אפשרויות הנגישות בתפריט</h4>
        <v-row class="margin-top-10">
          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="149.848px" height="98.323px" viewBox="0 0 149.848 98.323" xml:space="preserve">
              <path d="M41.418,51.493H5.412C2.423,51.493,0,53.917,0,56.905v36.007c0,2.986,2.423,5.409,5.412,5.409l36.006,0.002
              c2.989-0.004,5.408-2.421,5.412-5.411V56.905C46.826,53.912,44.407,51.496,41.418,51.493z M39.83,91.322H7l0.002-32.829H39.83
              V91.322z M24.111,85.87c0.684,0.683,1.58,1.023,2.475,1.023c0.896,0,1.792-0.341,2.476-1.023c1.365-1.367,1.365-3.584,0-4.949
              l-0.002-0.002l-6.012-6.012l6.012-6.012c1.367-1.367,1.367-3.584,0.002-4.948c-1.367-1.367-3.583-1.367-4.949,0l-8.491,8.487
              c-0.652,0.649-1.025,1.552-1.025,2.475c0,0.92,0.374,1.821,1.025,2.474L24.111,85.87z M144.436,51.493H108.43
              c-2.991,0.003-5.406,2.419-5.41,5.412v36.007c0.002,2.99,2.423,5.407,5.41,5.409v0.002h36.006c2.986-0.006,5.412-2.423,5.412-5.411
              V56.905C149.848,53.915,147.424,51.496,144.436,51.493z M142.848,91.322H110.02V58.493h32.828V91.322z M120.787,85.869
              c0.684,0.684,1.58,1.025,2.477,1.025c0.895,0,1.791-0.342,2.475-1.023l8.486-8.489c0.66-0.66,1.025-1.539,1.025-2.476
              c0-0.934-0.365-1.813-1.025-2.473l-8.488-8.487c-1.367-1.367-3.584-1.367-4.949,0c-1.367,1.367-1.367,3.583,0,4.95l6.012,6.01
              l-6.012,6.014C119.42,82.285,119.42,84.502,120.787,85.869z M92.945,51.493H56.938c-2.993,0.003-5.41,2.422-5.412,5.412v36.007
              c0.002,2.988,2.425,5.409,5.412,5.409l36.008,0.002c2.987-0.002,5.408-2.423,5.41-5.411V56.905
              C98.352,53.915,95.937,51.498,92.945,51.493z M91.354,91.322H58.525V58.493h32.83L91.354,91.322z M72.467,82.698
              c0.66,0.662,1.541,1.027,2.476,1.027c0.934-0.002,1.813-0.365,2.475-1.027l8.485-8.487c1.366-1.367,1.366-3.584,0-4.948
              c-1.368-1.367-3.584-1.367-4.95,0l-6.012,6.011l-6.012-6.011c-1.369-1.369-3.584-1.369-4.95,0c-1.367,1.366-1.367,3.581,0,4.95
              L72.467,82.698z M56.937,46.828l36.007,0.002c2.993-0.004,5.408-2.425,5.411-5.413V5.412C98.349,2.425,95.934,0,92.944,0H56.937
              c-2.987,0.002-5.409,2.419-5.411,5.412v36.005C51.53,44.409,53.945,46.824,56.937,46.828z M58.526,6.999l32.83,0.002v32.828h-32.83
              V6.999z M77.415,15.623c-0.651-0.652-1.554-1.025-2.476-1.025c-0.921,0-1.824,0.373-2.476,1.025l-8.485,8.489
              c-1.367,1.366-1.367,3.582,0,4.95c0.684,0.684,1.58,1.024,2.475,1.024c0.896,0.001,1.792-0.342,2.476-1.026l6.01-6.014l6.014,6.014
              c1.366,1.366,3.582,1.366,4.949,0c1.368-1.366,1.368-3.582,0.002-4.948L77.415,15.623z"></path> </svg>
             <span>התאמה לניווט מקלדת - מתן אפשרות לניווט על ידי מקלדת.</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="195.39px" height="63.871px" viewBox="0 0 195.39 63.871" xml:space="preserve">
              <path d="M38.185,28.062h-24.96l9.651-9.651c1.513-1.513,1.513-3.966,0-5.479s-3.966-1.513-5.479,0L1.133,29.194
              C0.411,29.915,0,30.915,0,31.935c0,1.021,0.411,2.019,1.133,2.739l16.263,16.265c0.756,0.757,1.748,1.135,2.739,1.135
              c0.992,0,1.983-0.378,2.74-1.135c1.513-1.513,1.513-3.966,0-5.479v-0.002l-9.65-9.649h24.959c2.14,0,3.874-1.735,3.874-3.873
              C42.059,29.795,40.325,28.062,38.185,28.062z M97.28,28.06h-9.434c-2.14,0-3.874,1.734-3.874,3.874c0,2.138,1.734,3.871,3.874,3.871
              h9.434c2.139,0,3.872-1.733,3.872-3.871C101.152,29.794,99.418,28.06,97.28,28.06z M194.256,29.195l-16.263-16.265
              c-1.515-1.513-3.966-1.513-5.479,0.002c-1.514,1.513-1.512,3.966,0,5.479l9.651,9.649h-24.961c-2.14,0-3.874,1.733-3.874,3.874
              c0,2.138,1.734,3.873,3.874,3.873h24.959l-9.649,9.65c-1.514,1.513-1.514,3.966,0,5.479c0.757,0.758,1.749,1.137,2.741,1.137
              c0.991,0,1.981-0.378,2.738-1.135l16.263-16.265c0.721-0.723,1.134-1.721,1.134-2.739C195.39,30.914,194.977,29.916,194.256,29.195z
              M69.542,0.45h-3.688c-1.259,0-2.219,0.241-2.878,0.72L43.724,15.924c-0.661,0.48-0.989,1.11-0.989,1.889
              c0,0.54,0.18,1.05,0.539,1.529l2.159,2.789c0.479,0.6,1.109,0.899,1.889,0.899c0.6,0,1.109-0.181,1.529-0.54l14.573-11.064v49.566
              c0,0.66,0.239,1.229,0.72,1.709c0.479,0.48,1.049,0.72,1.709,0.72h3.688c0.659,0,1.229-0.239,1.709-0.72
              c0.479-0.479,0.72-1.049,0.72-1.709V2.88c0-0.659-0.24-1.229-0.72-1.71C70.77,0.691,70.201,0.45,69.542,0.45z M148.093,55.775
              h-27.527l20.061-18.713c3.118-2.758,5.322-5.471,6.612-8.141c1.289-2.668,1.934-5.893,1.934-9.67c0-3.598-0.78-6.852-2.339-9.76
              c-1.56-2.908-3.854-5.219-6.882-6.928S133.28,0,129.022,0c-4.259,0-7.977,0.961-11.154,2.879c-3.18,1.92-5.622,4.379-7.332,7.377
              c-1.709,3-2.624,6.027-2.743,9.086c0,0.539,0.193,1.004,0.585,1.395c0.389,0.391,0.854,0.584,1.394,0.584h4.139
              c0.539,0,1.019-0.18,1.439-0.539c0.418-0.359,0.719-0.869,0.899-1.529c0.839-3.418,2.218-6.131,4.138-8.141
              c1.919-2.01,4.797-3.014,8.636-3.014c3.897,0,6.806,1.02,8.727,3.059c1.918,2.039,2.878,4.738,2.878,8.096
              c0,2.578-0.42,4.709-1.259,6.387c-0.841,1.68-2.22,3.389-4.139,5.127l-26.357,24.02c-0.78,0.719-1.305,1.305-1.574,1.754
              s-0.404,1.006-0.404,1.664v3.238c0,0.66,0.238,1.23,0.72,1.709c0.479,0.48,1.048,0.721,1.709,0.721h38.771
              c0.659,0,1.229-0.24,1.709-0.721c0.479-0.479,0.72-1.049,0.72-1.709v-3.238c0-0.658-0.24-1.229-0.72-1.709
              C149.322,56.016,148.752,55.775,148.093,55.775z"></path>
              </svg>
              <span>התאמה לקורא מסך - התאמר האתר עבור טכנולוגיות מסייעות כגון NVDA, JAWS<br></span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="81.216px" height="126.878px" viewBox="0 0 81.216 126.878" xml:space="preserve">
              <path d="M25.049,21.606c0.684,0,1.367-0.261,1.889-0.782v-0.002L41.312,6.446l14.375,14.376c1.041,1.043,2.732,1.043,3.775,0.002
              c1.043-1.043,1.045-2.734,0.002-3.777L43.201,0.783c-1.043-1.043-2.734-1.046-3.777,0L23.161,17.047
              c-1.043,1.043-1.043,2.732,0,3.777C23.683,21.346,24.366,21.606,25.049,21.606z M55.686,106.056l-14.375,14.375l-14.375-14.375
              c-1.043-1.043-2.734-1.043-3.777,0c-1.042,1.044-1.042,2.734,0.002,3.777l16.262,16.262c0.505,0.505,1.175,0.783,1.889,0.783
              s1.384-0.278,1.889-0.783l16.264-16.264c1.042-1.043,1.042-2.731-0.002-3.775C58.419,105.013,56.73,105.013,55.686,106.056z
              M80.431,93.841L47.327,27.287c-1.269-2.549-3.87-4.158-6.716-4.158c-2.847,0-5.449,1.609-6.714,4.158L0.787,93.841
              c-1.846,3.707-0.335,8.21,3.375,10.056c3.708,1.843,8.209,0.333,10.055-3.375l5.579-11.214h41.625L67,100.521
              c1.312,2.635,3.965,4.16,6.721,4.16c1.122,0,2.261-0.252,3.334-0.785C80.763,102.051,82.275,97.55,80.431,93.841z M27.258,74.308
              l13.353-26.84l13.35,26.84H27.258z"></path>
              </svg>
              <span>הגדלת פונט האתר ל־5 גדלים שונים</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="121.246px" height="122.861px" viewBox="0 0 121.246 122.861" xml:space="preserve">
              <path d="M120.062,5.953c-1.541-1.486-3.993-1.439-5.477,0.1L102.196,18.9c-0.265-0.284-0.52-0.578-0.789-0.857
              C90.691,6.918,75.799-0.004,59.374,0C42.946-0.004,28.054,6.918,17.338,18.043C6.614,29.161-0.002,44.52,0,61.431
              c-0.002,16.91,6.614,32.267,17.338,43.387c0.335,0.348,0.686,0.679,1.029,1.018L8.278,116.298c-1.484,1.539-1.439,3.991,0.1,5.478
              c0.752,0.725,1.721,1.086,2.689,1.086c1.015,0,2.029-0.397,2.788-1.187l10.362-10.746c9.831,7.485,21.998,11.936,35.156,11.932
              h0.015c16.419,0,31.306-6.924,42.019-18.043c10.725-11.12,17.342-26.477,17.34-43.387c0.002-13.646-4.312-26.279-11.611-36.491
              l13.026-13.509C121.646,9.892,121.601,7.439,120.062,5.953z M23.746,100.257c-0.276-0.273-0.559-0.539-0.829-0.818
              C13.556,89.728,7.749,76.306,7.746,61.431c0.002-14.878,5.81-28.3,15.171-38.011c9.371-9.709,22.228-15.669,36.456-15.674
              C73.6,7.751,86.457,13.711,95.83,23.42c0.335,0.348,0.654,0.709,0.979,1.066L78.5,43.475l-6.602-4.03
              c-1.528-0.938-3.255-1.391-4.93-1.388c-3.181-0.007-6.307,1.621-8.087,4.539c-0.905,1.483-1.386,3.191-1.386,4.932v17.729
              l-5.515,5.72c-0.507-0.285-1.083-0.462-1.707-0.462h-9.356c-1.933,0-3.5,1.566-3.5,3.5c0,1.933,1.566,3.5,3.5,3.5h4.758
              L23.746,100.257z M79.765,53.325l10.425,6.362c0.216,0.131,0.403,0.312,0.547,0.534c0.192,0.294,0.273,0.6,0.275,0.924
              c-0.005,0.57-0.269,1.114-0.788,1.454l-22.32,14.533c-0.281,0.183-0.61,0.28-0.938,0.28c-0.945-0.003-1.719-0.774-1.722-1.722
              v-7.309L79.765,53.325z M65.243,57.222v-9.694c0.003-0.318,0.088-0.623,0.255-0.898c0.326-0.536,0.882-0.82,1.471-0.825
              c0.313,0.003,0.598,0.077,0.884,0.251l5.137,3.135L65.243,57.222z M111.001,61.431c-0.002,14.875-5.809,28.297-15.171,38.008
              c-9.373,9.709-22.23,15.672-36.457,15.674c-11.077-0.002-21.313-3.628-29.73-9.81l27.888-28.921
              c0.358,4.906,4.437,8.774,9.434,8.778c1.839,0,3.632-0.536,5.165-1.534l22.328-14.538c2.777-1.818,4.309-4.856,4.302-7.942
              c0.002-1.766-0.501-3.575-1.534-5.158c-0.771-1.183-1.793-2.177-2.997-2.912l-8.953-5.466l16.372-16.979
              C107.529,39.342,110.999,49.948,111.001,61.431z M53.775,49.849c0-1.934-1.566-3.5-3.5-3.5H28.917c-1.932,0-3.499,1.566-3.499,3.5
              c0,1.932,1.566,3.499,3.499,3.499h21.357C52.208,53.348,53.775,51.78,53.775,49.849z M53.775,61.932
              c0-1.933-1.567-3.499-3.499-3.499H35.917c-1.933,0-3.499,1.566-3.499,3.499s1.566,3.502,3.499,3.502h14.358
              C52.208,65.434,53.775,63.864,53.775,61.932z"></path> </svg>
              <span>חסימת הבהובים - עצירת אלמנטים נעים וחסימת הבהובים</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="77.501px" height="80.057px" viewBox="0 0 77.501 80.057" xml:space="preserve">
              <path d="M76.881,57.929c-0.743-0.88-1.992-1.206-3.092-0.827c-3.081,1.061-6.424,1.607-9.941,1.607
              c-16.84,0-30.538-13.173-30.538-29.355c0-9.741,5.004-18.808,13.382-24.262c0.949-0.62,1.402-1.735,1.148-2.809
              c-0.254-1.071-1.172-1.884-2.309-2.043C44.231,0.058,42.938,0,41.64,0C18.676,0,0,17.954,0,40.028s18.676,40.028,41.64,40.028
              c14.572,0,27.829-7.12,35.454-19.043C77.708,60.048,77.62,58.815,76.881,57.929z"></path>
              </svg>
              <span>התאמת נגודויות - שינוי ניגודיות צבעים על בסיס רקע כהה</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="127.003px" height="127.002px" viewBox="0 0 127.003 127.002" xml:space="preserve">
              <path d="M63.502,24.318c-21.64,0.004-39.178,17.542-39.18,39.184c0.002,21.64,17.54,39.178,39.18,39.182
              c21.64-0.004,39.18-17.542,39.183-39.182C102.682,41.86,85.142,24.322,63.502,24.318z M63.502,95.682
              c-17.771-0.032-32.148-14.408-32.18-32.18C31.354,45.729,45.73,31.35,63.502,31.32c17.771,0.029,32.149,14.408,32.182,32.182
              C95.651,81.273,81.273,95.649,63.502,95.682z M63.502,110.851c-1.933,0-3.5,1.565-3.5,3.499v9.153c0,1.932,1.567,3.499,3.5,3.499
              c1.932,0,3.501-1.567,3.501-3.499v-9.153C67.003,112.416,65.434,110.851,63.502,110.851z M63.501,16.151
              c1.934,0,3.502-1.565,3.502-3.499V3.501C67.003,1.567,65.435,0,63.501,0c-1.933,0-3.499,1.567-3.499,3.501v9.151
              C60.002,14.586,61.568,16.151,63.501,16.151z M14.47,60.002H3.501C1.567,60.002,0,61.568,0,63.501s1.567,3.501,3.501,3.501H14.47
              c1.933,0,3.499-1.568,3.499-3.501S16.403,60.002,14.47,60.002z M123.503,60.002h-10.969c-1.932,0-3.499,1.566-3.499,3.499
              s1.567,3.501,3.499,3.501h10.969c1.933,0,3.5-1.568,3.5-3.501S125.436,60.002,123.503,60.002z M25.741,96.313l-7.142,7.142
              c-1.367,1.366-1.367,3.583,0,4.949c0.683,0.684,1.58,1.025,2.476,1.025c0.895,0,1.791-0.341,2.473-1.025l7.143-7.14
              c1.368-1.366,1.368-3.583,0.002-4.949C29.326,94.946,27.109,94.946,25.741,96.313z M98.789,31.714c0.896,0,1.792-0.341,2.476-1.024
              l7.139-7.14c1.367-1.369,1.367-3.584,0-4.95c-1.366-1.366-3.583-1.366-4.949,0l-7.141,7.14c-1.366,1.366-1.366,3.582,0,4.95
              C96.997,31.373,97.894,31.714,98.789,31.714z M101.263,96.313c-1.366-1.366-3.582-1.366-4.951,0c-1.366,1.366-1.366,3.582,0,4.949
              l7.143,7.142c0.684,0.684,1.578,1.025,2.475,1.025c0.896,0,1.791-0.342,2.475-1.025c1.366-1.368,1.366-3.583,0-4.951L101.263,96.313
              z M25.741,30.689c0.683,0.684,1.579,1.025,2.474,1.025c0.896,0,1.792-0.342,2.476-1.025c1.366-1.366,1.366-3.584,0-4.95l-7.144-7.14
              c-1.367-1.368-3.582-1.366-4.948,0c-1.368,1.366-1.366,3.584,0,4.95L25.741,30.689z"></path>
            </svg>
            <span>התאת ניגודיות - שינוי ניגודיות צבעים על בסיס רקע בהיר</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="87.056px" height="87.057px" viewBox="0 0 87.056 87.057" xml:space="preserve">
              <path d="M43.526,0C19.486,0.002,0.002,19.487,0,43.528c0.002,24.041,19.486,43.524,43.526,43.528
              c24.043-0.004,43.526-19.487,43.529-43.528C87.053,19.487,67.569,0.002,43.526,0z M47.028,7.171
              c0.881,0.084,1.751,0.203,2.613,0.348v72.018c-0.862,0.146-1.732,0.265-2.613,0.348V7.171z M56.642,9.435
              c0.889,0.342,1.764,0.711,2.616,1.118v65.95c-0.853,0.407-1.728,0.776-2.616,1.118V9.435z M66.257,14.944
              c0.932,0.742,1.825,1.527,2.68,2.355c-0.037,0.203-0.063,0.411-0.063,0.626V69.6c0,0.068,0.016,0.132,0.02,0.198
              c-0.842,0.813-1.721,1.584-2.637,2.313V14.944z M75.872,26.557c1.076,2.044,1.963,4.2,2.639,6.45
              c-0.005,0.075-0.022,0.146-0.022,0.222V53.95c0,0.045,0.012,0.086,0.013,0.131c-0.674,2.238-1.559,4.384-2.629,6.418V26.557z
              M7,43.528c0-10.098,4.083-19.21,10.697-25.829c5.847-5.843,13.636-9.703,22.33-10.528v72.714
              c-8.694-0.825-16.483-4.685-22.33-10.527C11.083,62.735,7,53.626,7,43.528z"></path>
            </svg>
            <span>התאמת אתר לעיוורי צבעים</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="121.942px" height="125.554px" viewBox="0 0 121.942 125.554" xml:space="preserve">
              <path d="M17.228,95.437c1.073,0.534,2.213,0.787,3.336,0.787c2.756,0,5.409-1.525,6.721-4.161l7.263-14.602h52.848l7.264,14.602
              c1.845,3.707,6.346,5.219,10.055,3.374c3.708-1.844,5.219-6.345,3.375-10.055l-40.4-81.223C66.42,1.61,63.82,0,60.973,0
              c-2.846,0-5.445,1.61-6.715,4.159L13.854,85.382C12.01,89.089,13.521,93.591,17.228,95.437z M60.973,24.339l18.963,38.122H42.01
              L60.973,24.339z M114.443,110.555H7.501c-4.144,0-7.501,3.357-7.501,7.499s3.358,7.5,7.501,7.5h106.942
              c4.141,0,7.499-3.358,7.499-7.5S118.584,110.555,114.443,110.555z"></path>
            </svg>
            <span>שינוי הפונט לקריא יותר</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93.647px" height="127.401px" viewBox="0 0 93.647 127.401" xml:space="preserve">
              <path d="M53.389,127.257c-0.89-0.264-1.627-0.858-2.072-1.676l0,0l-20.749-38.16L8.229,110.619
              c-0.978,1.014-2.464,1.339-3.776,0.826l0,0c-1.31-0.514-2.183-1.76-2.212-3.167l0,0l-1.12-52.361L0.001,3.555
              c-0.028-1.301,0.677-2.513,1.818-3.132l0,0C2.959-0.199,4.36-0.131,5.436,0.6l0,0L48.778,30l43.344,29.4l0,0
              c1.164,0.79,1.736,2.2,1.453,3.58l0,0c-0.28,1.378-1.362,2.449-2.744,2.717l0,0L59.22,71.84l20.752,38.159
              c0.443,0.817,0.543,1.757,0.279,2.649l0,0c-0.262,0.892-0.857,1.627-1.674,2.071l0,0l-22.539,12.256
              c-0.518,0.281-1.086,0.426-1.661,0.426l0,0C54.047,127.401,53.715,127.354,53.389,127.257L53.389,127.257z M31.823,78.139
              c1.095,0.146,2.063,0.813,2.592,1.787l0,0l21.355,39.272l16.425-8.931L50.838,70.992c-0.528-0.972-0.563-2.146-0.092-3.146l0,0
              c0.471-0.999,1.399-1.723,2.483-1.934l0,0l28.055-5.452l-36.414-24.7L7.105,10.144l0.976,45.624l0.941,43.99l19.826-20.586
              c0.658-0.684,1.57-1.064,2.509-1.064l0,0C31.512,78.107,31.667,78.117,31.823,78.139L31.823,78.139z"></path>
            </svg>
            <span>הגדלת הסמן ושינוי צבעו לשחור או לבן</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="131.859px" height="131.861px" viewBox="0 0 131.859 131.861" xml:space="preserve">
              <path d="M128.781,108.985L97.308,77.51c4.254-7.538,6.692-16.236,6.693-25.509C103.998,23.28,80.721,0.004,51.999,0
              C23.28,0.004,0.002,23.28,0,52.001c0.002,28.72,23.278,51.997,51.999,52.002c9.273-0.002,17.971-2.44,25.509-6.693l31.478,31.476
              c2.039,2.041,4.746,3.078,7.424,3.076h0.016c2.67,0,5.372-1.037,7.408-3.076l4.95-4.949c2.043-2.045,3.08-4.75,3.076-7.425
              C131.863,113.733,130.826,111.026,128.781,108.985z M20.181,83.819C12.031,75.666,7.002,64.439,7,52.001
              c0.002-12.438,5.032-23.666,13.181-31.82c8.154-8.147,19.38-13.179,31.818-13.179c12.438,0,23.667,5.031,31.82,13.179
              c8.148,8.154,13.178,19.383,13.18,31.82c-0.001,6.755-1.495,13.147-4.156,18.89L71.294,21.7h-8.962L33.951,86.487h12.548
              l5.377-12.957H81.75l3.601,8.678c-0.497,0.549-1.007,1.087-1.531,1.611c-8.153,8.15-19.382,13.18-31.82,13.182
              C39.561,96.999,28.335,91.97,20.181,83.819z M77.985,62.929H55.641l11.172-29.567L77.985,62.929z M123.831,118.888l-4.947,4.945
              c-0.692,0.692-1.568,1.022-2.475,1.027c-0.906-0.002-1.783-0.335-2.474-1.027L83.481,93.38c3.727-2.84,7.059-6.171,9.898-9.898
              l30.453,30.454c0.69,0.692,1.023,1.567,1.027,2.476C124.856,117.315,124.523,118.191,123.831,118.888z"></path>
            </svg>
            <span>הגדלת התצוגה לכ־200%</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="139.76px" height="125.288px" viewBox="0 0 139.76 125.288" xml:space="preserve">
              <path d="M125.297,27.496c0-3.546-1.368-6.879-3.855-9.386l-14.099-14.22C104.856,1.382,101.552,0,98.035,0s-6.822,1.382-9.308,3.889
              l-63.23,63.775c-3.766,3.795-5.836,8.843-5.836,14.213c0,2.269-0.913,4.49-2.505,6.096l-5.437,5.483
              c-1.415,1.428-2.227,3.402-2.227,5.421c0,1.318,0.35,2.619,0.982,3.764l-9.4,9.48c-0.776,0.785-1.165,1.88-1.058,2.984
              c0.11,1.104,0.704,2.099,1.617,2.715l9.437,6.344c0.621,0.419,1.331,0.623,2.037,0.623c0.945,0,1.885-0.367,2.596-1.084l7.828-7.896
              c1.133,0.642,2.423,0.994,3.73,0.994c2,0,3.961-0.821,5.375-2.248l5.438-5.483c1.59-1.604,3.794-2.523,6.043-2.523
              c5.324,0,10.328-2.092,14.093-5.888l63.23-63.773C123.929,34.376,125.297,31.044,125.297,27.496z M44.118,99.142
              c-4.243,0-8.232,1.666-11.234,4.692l-5.437,5.484c0,0,0,0-0.001,0c-0.097,0.1-0.271,0.098-0.369,0l-9.016-9.093c0,0,0,0,0-0.002
              l-1.151-1.159c-0.115-0.117-0.091-0.284,0-0.374l5.438-5.482c3.003-3.028,4.654-7.052,4.654-11.331c0-1.687,0.323-3.322,0.94-4.835
              l20.97,21.149C47.412,98.813,45.79,99.142,44.118,99.142z M54.785,93.642L32.454,71.118l53.785-54.247l22.332,22.522L54.785,93.642z
              M98.145,118.288v-0.003h-8.679c-3.173,0-6.015-1.275-8.097-3.354c-2.081-2.086-3.354-4.927-3.356-8.099
              c0.003-3.175,1.278-6.018,3.354-8.1c2.085-2.081,4.927-3.356,8.1-3.356h8.679c1.934,0,3.5-1.566,3.5-3.5s-1.566-3.5-3.5-3.5h-8.679
              c-5.085-0.003-9.72,2.071-13.048,5.405c-3.334,3.328-5.408,7.964-5.406,13.051c-0.002,5.084,2.072,9.72,5.406,13.047
              c3.328,3.335,7.963,5.408,13.048,5.406h8.679c1.934,0,3.5-1.566,3.5-3.5S100.078,118.288,98.145,118.288z M134.355,93.782
              c-3.327-3.334-7.965-5.408-13.049-5.405h-8.677c-1.934,0-3.503,1.566-3.503,3.5s1.569,3.5,3.503,3.5h8.677
              c3.174,0,6.015,1.275,8.099,3.354c2.078,2.087,3.354,4.928,3.356,8.103c-0.003,3.172-1.278,6.015-3.356,8.097
              c-2.084,2.08-4.925,3.355-8.099,3.358l-8.677-0.003c-1.934,0-3.503,1.567-3.503,3.501s1.569,3.502,3.503,3.502l8.677-0.002
              c5.084,0.002,9.722-2.071,13.049-5.406c3.335-3.327,5.406-7.963,5.404-13.047C139.762,101.746,137.69,97.11,134.355,93.782z
              M88.136,106.643c0,1.932,1.568,3.5,3.501,3.5h27.5c1.932,0,3.5-1.568,3.5-3.5c0-1.935-1.568-3.501-3.5-3.501h-27.5
              C89.704,103.142,88.136,104.708,88.136,106.643z"></path>
            </svg>
            <span>הדגשת קישורים באתר</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="124.787px" height="125.286px" viewBox="0 0 124.787 125.286" xml:space="preserve">
              <path d="M120.948,18.107l-14.043-14.22C104.427,1.38,101.137,0,97.634,0c-3.501,0-6.792,1.38-9.269,3.888L25.393,67.663
              c-3.75,3.795-5.815,8.843-5.815,14.213c0,2.269-0.908,4.488-2.491,6.093l-5.418,5.484c-1.407,1.43-2.216,3.404-2.216,5.423
              c0,1.319,0.348,2.62,0.98,3.764l-9.362,9.48c-0.774,0.785-1.162,1.88-1.053,2.982c0.107,1.104,0.699,2.101,1.609,2.715l9.396,6.347
              c0.62,0.418,1.326,0.622,2.027,0.622c0.945,0,1.879-0.37,2.587-1.084l7.797-7.896c1.129,0.642,2.412,0.992,3.717,0.992
              c1.991,0,3.943-0.817,5.352-2.246l5.417-5.483c1.584-1.604,3.776-2.523,6.017-2.523c5.303,0,10.288-2.092,14.035-5.887
              l62.976-63.776c2.475-2.507,3.839-5.839,3.839-9.387C124.787,23.949,123.422,20.616,120.948,18.107z M43.937,99.141
              c-4.225,0-8.2,1.666-11.189,4.69l-5.414,5.486c0,0,0,0-0.001,0c-0.096,0.098-0.268,0.098-0.367,0l-8.979-9.095l-1.146-1.161
              c-0.114-0.115-0.09-0.281,0-0.372l5.416-5.482c2.989-3.028,4.635-7.052,4.635-11.331c0-1.687,0.321-3.322,0.937-4.837l20.884,21.149
              C47.217,98.812,45.604,99.141,43.937,99.141z M54.561,93.639l-22.24-22.521l53.566-54.248l22.242,22.523L54.561,93.639z
              M82.516,121.786c0,1.934,1.567,3.5,3.5,3.5c1.934,0,3.501-1.566,3.501-3.5v-13.264h17.5v13.264c0,1.934,1.566,3.5,3.498,3.5
              c1.934,0,3.5-1.566,3.5-3.5V88.264c0-1.934-1.566-3.5-3.5-3.5c-1.932,0-3.498,1.566-3.498,3.5v13.26h-17.5v-13.26
              c0-1.934-1.567-3.5-3.501-3.5c-1.933,0-3.5,1.566-3.5,3.5"></path>
            </svg>
            <span>הדגשת כותרות באתר</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="119.589px" height="132.002px" viewBox="0 0 119.589 132.002" xml:space="preserve">
              <path d="M110.089,0H9.5C4.251,0.002,0.002,4.252,0,9.502V122.5c0.002,5.249,4.251,9.498,9.5,9.5l100.589,0.002
              c5.25-0.004,9.498-4.253,9.5-9.502V9.502C119.587,4.254,115.339,0.002,110.089,0z M112.59,122.5
              c-0.002,1.381-1.12,2.499-2.501,2.501H9.5c-1.378-0.002-2.499-1.122-2.501-2.501V9.502C7.001,8.122,8.122,7.004,9.5,7.001h100.589
              c1.381,0.003,2.499,1.121,2.501,2.501V122.5z M96.38,102.97H23.214c-1.676,0-3.031,1.357-3.031,3.031s1.355,3.031,3.031,3.031H96.38
              c1.674,0,3.031-1.357,3.031-3.031S98.054,102.97,96.38,102.97z M96.378,22.97H23.212c-0.798,0-1.58,0.323-2.143,0.889
              c-0.565,0.563-0.887,1.345-0.887,2.144v53.333c0,0.809,0.316,1.569,0.887,2.144c0.574,0.571,1.334,0.887,2.143,0.887h73.166
              c0.812,0,1.571-0.315,2.144-0.887c0.573-0.574,0.89-1.335,0.89-2.144V26.002c0-0.799-0.324-1.58-0.89-2.144
              C97.958,23.293,97.177,22.97,96.378,22.97z M93.349,60.079L80.251,46.984c-1.184-1.185-3.102-1.187-4.285,0L61.62,61.33
              L45.448,38.736c-0.536-0.747-1.376-1.211-2.295-1.262s-1.808,0.315-2.422,1.001L26.245,54.592v-25.56h67.104V60.079z"></path>
            </svg>
            <span>הצגת תיאור אלטרנטיבי לתמונות</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><g><g>
              <path d="M81.7,86.4L78,56.7H49.9l-1-15.5h21.1l-0.8-6.7H48.5L47.3,15c2.8-1.3,4.7-4.1,4.7-7.4c0-4.5-3.7-8.2-8.2-8.2
              s-8.2,3.7-8.2,8.2c0,3.6,2.4,6.7,5.7,7.7l3,47.3h28.4l3.7,29.7H88v-5.9L81.7,86.4L81.7,86.4z"></path>
              <path d="M45.1,93.5c-14.4,0-26.2-11.7-26.2-26.2c0-11.7,7.7-21.6,18.3-24.9l-0.4-6.7c-14,3.7-24.4,16.4-24.4,31.6
              C12.4,85.4,27,100,45.1,100c10,0,19-4.5,25-11.6l-1.3-10.1C64.7,87.2,55.6,93.5,45.1,93.5z"></path>
              </g></g>
            </svg>
            <span>הצהרת נגישות</span>
          </v-col>

          <v-col cols=12 sm=4 align=center>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 73.1" style="enable-background:new 0 0 100 73.1;" xml:space="preserve"><g>
              <path d="M94.9,1.6H5.1C3.9,1.6,3,2.6,3,3.7V69c0,1.2,0.9,2.1,2.1,2.1h89.9c1.2,0,2.1-0.9,2.1-2.1V3.7C97,2.6,96.1,1.6,94.9,1.6z
              M92.9,56L68.8,36.4L93,17.4L92.9,56z M92.9,5.8v6.4L50,45.7L7.1,10.9V5.8L92.9,5.8z M7.1,16.2l24.8,20.1L7.1,56.1L7.1,16.2z
              M7.1,66.9v-5.5L35.2,39l13.5,10.9c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.4l14-10.9l27.6,22.3v5.5L7.1,66.9z"></path>
              </g>
            </svg>
            <span>שליחת משוב נגישות</span>
          </v-col>
        </v-row>
        <v-divider class="margin-tb-30"/>
      </div>

      <v-card class="accessibility-page-contact">
        <v-card-title>
          {{$t('accessibility.contact.intro')}}
        </v-card-title>
        <v-card-title>{{$t('accessibility.contact.contact_details_title')}}</v-card-title>
        <v-card-text>
          <div class="accessibility-page-contact-details">
            <div>{{$t('accessibility.contact.contact_details_name')}}: {{$config.accessibilityName}}</div>
            <div>{{$t('accessibility.contact.contact_details_phone')}}: {{$config.mainPhone}}</div>
            <div>{{$t('accessibility.contact.contact_details_email')}}: {{$config.accessibilityEmail}}</div>
            <vue-markdown>{{ $t('accessibility.contact.contact_page_link') }}</vue-markdown>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'accessibility',

  metaInfo () {
    return {
      title: 'Accessibility'
    }
  },

  components: {
    // QuickContact,
    HeaderClean
  },

  data () {
    return {
    }
  }
}
</script>

<style lang="scss" >
  .accessibility-page {

    h3 {
      font-size: $font-lg;
    }

    &-contact {
      margin-top: 50px;

      &-details {
        font-size: $font-sm;
        color: var(--v-secondary-base);
      }
    }

    &-menu {

      svg {
        width: 50px;
        height: 50px;
        display: block;
        position: relative;
        right: 0;
        top: 8px;
        text-align: center;
        margin: 0 auto 20px;
        vertical-align: top;

        path {
          fill: var(--v-accent-base);
          stroke: var(--v-accent-base);
        }
      }
    }
  }
</style>
