<template>
  <v-card :to="{ name }" height="100%" :disabled="!name">
    <v-img max-height="150" :src="image" :contain="contain"/>
    <v-card-title class="">
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {

  name: 'serviceCard',

  props: ['name', 'image', 'title', 'subtitle', 'contain']
}
</script>

<style lang="scss" scoped>
</style>
