<template>
  <div class="static-page sale-page">

    <HeaderClean image="/app_images/house-space.webp" :header="$t('sale.title')" :subheader="$t('sale.subtitle')" :options="{opacity: '70', background: 'black'}"/>

    <Spacer height="30px"/>

    <v-container fluid>
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="8">
          <h3 class="text-center">{{ $t('sale.subtext') }}</h3>
        </v-col>
      </v-row>
    </v-container>

    <!-- <spacer height="30px"/>
    <v-divider/>
    <spacer height="30px"/> -->

    <v-container tag="section">
      <spacer height="80px" v-if="$vuetify.breakpoint.smAndUp"/>
      <TextImageRow v-for="(row, i) in textImageRows" :img="row.img" :title="row.title" :text="row.text" :reverse="i % 2 === 0" :key="i"/>
      <spacer height="50px" v-if="$vuetify.breakpoint.smAndUp"/>
    </v-container>

    <spacer height="30px"/>
    <v-divider/>

    <v-container tag="section" fluid class="colored-section">
      <v-container>
        <spacer height="30px"/>
        <v-row align="center" justify="center">
          <v-col :class="{'text-center': $vuetify.breakpoint.smAndDown}">
            <h3 class="padding-bottom-10">{{ $t('sale.content_title') }}</h3>
            <vue-markdown>{{ $t('sale.content')[0] }}</vue-markdown>
            <vue-markdown>{{ $t('sale.content')[1] }}</vue-markdown>
            <vue-markdown>{{ $t('sale.content')[2] }}</vue-markdown>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-divider/>

    <v-container tag="section" fluid class="padding-tb-50 ">
      <v-row class="align-center justify-center font-20" no-gutters>
        <v-col cols="12" sm="10">
          <vue-markdown>{{ $t("buy.contact") }}</vue-markdown>
          <QuickContact inline page="sale"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TextImageRow from '@/common/components/text-image-row'
import QuickContact from '@/common/components/quick-contact'
import rules from '@/common/mixins/validations-rules-mixin.js'
import { Api } from '@/common/services/api-service.js'
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'Sale',

  metaInfo () {
    return {
      title: 'Sale',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.sale.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.sale.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.sale.description'),
          vmid: 'description'
        }
      ]
    }
  },

  mixins: [rules],

  components: {
    TextImageRow,
    QuickContact,
    HeaderClean
  },

  data () {
    return {
      textImageRows: [
        {
          img: this.$cloudinary.url('/logos/logo.webp', { width: 450, crop: 'fit', background: 'rgb:0c0c0c' }),
          title: this.$t('sale.text_images_rows.choose.title'),
          text: this.$t('sale.text_images_rows.choose.text')
        },
        {
          img: this.$cloudinary.url('/app_images/magnifying-glass.webp', { width: 450, crop: 'fit' }),
          title: this.$t('sale.text_images_rows.do.title'),
          text: this.$t('sale.text_images_rows.do.text')
        },
        {
          img: this.$cloudinary.url('/app_images/business-digital.webp', { width: 450, crop: 'fit' }),
          title: this.$t('sale.text_images_rows.create.title'),
          text: this.$t('sale.text_images_rows.create.text')
        },
        {
          img: this.$cloudinary.url('/app_images/agent.webp', { width: 450, crop: 'fit' }),
          title: this.$t('sale.text_images_rows.cosmos.title'),
          text: this.$t('sale.text_images_rows.cosmos.text')
        },
        {
          img: this.$cloudinary.url('/app_images/contract.webp', { width: 450, crop: 'fit' }),
          title: this.$t('sale.text_images_rows.nobility.title'),
          text: this.$t('sale.text_images_rows.nobility.text')
        }
      ]
    }
  },

  methods: {
    showSnackbar (type) {
      this.snackbarMessage = this.$t(`contact.${type}_message`)
      this.snackbarColor = type
      this.snackbar = true
    },

    async contact () {
      if (this.$refs.form.validate()) {
        this.btnDisabled = true
        try {
          await Api.post('/contacts', this.formData)
          this.showSnackbar('success')
          setTimeout(() => {
            this.$router.push({ name: 'home', path: '*' })
          }, 5000)
        } catch (e) {
          console.error(e)
          this.showSnackbar('error')
          this.btnDisabled = false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">

</style>
