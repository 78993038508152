<template>
  <v-row>
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" v-for="service in renderedServices" :key="service.name">
      <service-card :name="service.name" :image="service.image" :title="service.title" :subtitle="service.subtitle"/>
    </v-col>
  </v-row>
</template>

<script>

import serviceCard from './service-card'

export default {

  name: 'sevicesCards',

  props: {
    services: Array,
    cols: {
      type: Number,
      default: 12
    },
    sm: {
      type: Number,
      default: 6
    },
    md: {
      type: Number,
      default: 4
    },
    lg: {
      type: Number,
      default: 4
    }
  },

  components: {
    serviceCard
  },

  data () {
    return {
      renderedServices: [],
      availableServices: [
        {
          name: 'sale',
          image: this.$cloudinary.url('/app_images/house-space.webp', { width: 400, crop: 'fit', transformation: { effect: 'colorize:15' } }),
          title: this.$t('sale.title'),
          subtitle: this.$t('sale.subtitle')
        },
        {
          name: 'buy',
          image: this.$cloudinary.url('/app_images/living.webp', { width: 400, crop: 'fit' }),
          title: 'המשימה שלנו היא לעזור לכולם למצוא את חלל המגורים שמגדיר אותם בעולם',
          subtitle: ''
        },
        {
          name: 'mortgage',
          image: this.$cloudinary.url('/app_images/mortgaga.webp', { width: 400, crop: 'fit' }),
          title: 'הצטרפו ללקוחות שכבר קיבלו משכנתא וחסכו מאות אלפי שקלים בעזרת הטכנולוגיה שלנו',
          subtitle: ''
        }
      ]
    }
  },

  created () {
    if (!this.services || !this.services.length) {
      this.renderedServices = this.availableServices
    } else {
      this.availableServices.reduce((rs, s) => {
        if (this.services.indexOf(s.name) > -1) {
          rs.push(s)
        }
        return rs
      }, this.renderedServices)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
