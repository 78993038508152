<template>
  <div class="static-page buy-page">

    <HeaderClean :header="$t('buy.title')" :subheader="$t('buy.subtitle_1')"/>

    <v-divider/>

    <v-container fluid class="padding-tb-50">
      <h3 class="font-20 text-center">{{ $t('buy.subtitle_2') }}</h3>
    </v-container>

    <v-container tag="section">
      <TextImageRow v-for="(row, i) in textImageRows" :img="row.img" :title="row.title" :text="row.text" :reverse="i % 2 !== 0" :key="i"/>
      <Spacer height="50px" v-if="$vuetify.breakpoint.smAndUp"/>
    </v-container>

    <v-divider/>

    <v-container fluid class="padding-tb-50">
      <v-row class="align-center justify-center font-20" no-gutters>
        <v-col cols="12" sm="10">
          <vue-markdown class="text-center">
            {{ $t('buy.about') }}
          </vue-markdown>
        </v-col>
      </v-row>
    </v-container>

    <v-divider/>

    <v-container fluid class="padding-tb-50 colored-section">
      <v-row class="align-center justify-center font-20" no-gutters>
        <v-col cols="12" sm="10">
          <vue-markdown>{{ $t("buy.contact") }}</vue-markdown>
          <QuickContact inline page="buy"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TextImageRow from '@/common/components/text-image-row'
import QuickContact from '@/common/components/quick-contact'
import rules from '@/common/mixins/validations-rules-mixin.js'
import { Api } from '@/common/services/api-service.js'
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'buy',

  metaInfo () {
    return {
      title: 'Buy',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.buy.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.buy.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.buy.description'),
          vmid: 'description'
        }
      ]
    }
  },

  mixins: [rules],

  components: {
    TextImageRow,
    QuickContact,
    HeaderClean
  },

  data () {
    return {
      formData: {
        name: '',
        city: '',
        phone: '',
        subject: 'sale page',
        message: ''
      },
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      btnDisabled: false,
      textImageRows: [
        {
          img: this.$cloudinary.url('/app_images/agent.webp', { width: 450, crop: 'fit' }),
          title: this.$t('buy.text_images_rows.lets_meet.title'),
          text: this.$t('buy.text_images_rows.lets_meet.text')
        },
        {
          img: this.$cloudinary.url('/app_images/business-digital.webp', { width: 450, crop: 'fit' }),
          title: this.$t('buy.text_images_rows.road.title'),
          text: this.$t('buy.text_images_rows.road.text')
        },
        {
          img: this.$cloudinary.url('/app_images/about-header-1.webp', { width: 450, crop: 'fit' }),
          title: this.$t('buy.text_images_rows.transparency.title'),
          text: this.$t('buy.text_images_rows.transparency.text')
        },
        {
          img: this.$cloudinary.url('/app_images/contract.webp', { width: 450, crop: 'fit' }),
          title: this.$t('buy.text_images_rows.congrats.title'),
          text: this.$t('buy.text_images_rows.congrats.text')
        }
      ]
    }
  },

  methods: {
    showSnackbar (type) {
      this.snackbarMessage = this.$t(`contact.${type}_message`)
      this.snackbarColor = type
      this.snackbar = true
    },

    async contact () {
      if (this.$refs.form.validate()) {
        this.btnDisabled = true
        try {
          await Api.post('/contacts', this.formData)
          this.showSnackbar('success')
          setTimeout(() => {
            this.$router.push({ name: 'home', path: '*' })
          }, 5000)
        } catch (e) {
          console.error(e)
          this.showSnackbar('error')
          this.btnDisabled = false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">

  .buy-page {

    .logo-section {
      background-color: var(--v-secondary-base);
      text-align: center;
    }
  }
</style>
