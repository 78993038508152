<template>
  <div class="static-page family-economic-page">
    <HeaderClean :header="$t('family_economy.title')"/>

    <v-container tag="section" class="padding-top-35">
      <v-row>
        <v-col md=8 lg=9 cols=10 offset=1 offset-md=0>
          <v-row>
            <v-col>
              <h2>{{ $t('family_economy.intro_title') }}</h2>
              <vue-markdown>{{ $t('family_economy.intro_text') }}</vue-markdown>
              <p>
                {{ $t('family_economy.download_text') }} - <v-btn :href="downloadPdf()" small color="primary">{{ $t('family_economy.download_button') }}</v-btn>
              </p>
            </v-col>
          </v-row>

          <aside v-if="articles.length">
            <v-row>
              <v-col cols=12 sm=6 md=4>
                <article-box :md="6" v-for="art in articles" :article="art" image-width="250" :key="art.id" small/>
              </v-col>
            </v-row>
          </aside>
        </v-col>

        <v-col cols=10 offset=1 md=4 lg=3 offset-md=0>
          <quick-contact page="family econmic">
            <template v-slot:hidden-fields>
              <input type="hidden" name="subject" value="family econmic contact">
              <input type="hidden" name="message" value="family econmic contact">
            </template>
            <template v-slot:content>
              <div class="text-center font-18 font-600">
                <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
                {{ $t("common.or") }}
                <a :href="$config.whatsappPhone | whatsapp($t('family_economy.whatsapp_message'))" target="_blank">
                  {{ $t("quick_contact.or_whatsapp") }}
                  <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
                </a>
              </div>
            </template>
          </quick-contact>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'
import QuickContact from '@/common/components/quick-contact'
import ArticleBox from '@/app/modules/magazine/components/article-box'
import MagazineService from '@/app/modules/magazine/magazine-service'

export default {

  name: 'FamilyEconomic',

  // TODO: meta

  components: {
    QuickContact,
    HeaderClean,
    ArticleBox
  },

  data () {
    return {
      articles: []
    }
  },

  methods: {
    downloadPdf () {
      return this.$cloudinary.url('pdfs/family-economy.pdf', { flags: 'attachment' })
    }
  },

  async created () {
    this.articles = await MagazineService.searchArticles('כלכלה המשפחה', 1, 3)
  }
}
</script>

<style lang="scss" scoped>
</style>
