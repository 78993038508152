<template>
  <div class="static-page greece-page">
    <header-parallax :header="$t('greece.title')" image="app_images/greece-header1.webp" :options="{ crop: 'fill', y: 250, effect: 'brightness:-20'}"/>

    <v-container tag="section" class="padding-top-20">
      <v-row>
        <v-col>
          <v-row>
            <v-col offset-sm="9">
              <strong class="badge">{{ $t('greece.badge') }}</strong>
            </v-col>
          </v-row>

          <h2 class="text-center">{{ $t('greece.invest_title') }}</h2>
          <v-row class="padding-tb-20">
            <v-col v-for="card in cards" :key="card.name">
              <service-card :title="card.title" :subtitle="card.subtitle" :image="card.image" contain/>
            </v-col>
          </v-row>

          <v-divider class="margin-top-35 margin-bottom-50"/>

          <section>
            <v-row>
              <v-col cols=12 sm=7 class="font-18">
                <h2>{{ $t('greece.intro_title') }}</h2>
                <vue-markdown>{{ $t('greece.intro_text') }}</vue-markdown>
              </v-col>
              <v-col>
                <div class="img-shadow">
                  <cld-image publicId="app_images/greece_intro.webp">
                    <cld-transformation width="500" crop="fit"/>
                  </cld-image>
                </div>
              </v-col>
            </v-row>
          </section>

          <v-divider class="margin-top-50"/>

          <v-row class="padding-tb-50">
            <v-col sm=7 lg=8 cols=12 offset=0 offset-sm=1 class="font-18">
              <!-- <div class="greece-page-corporate">
                <cld-image publicId="app_images/greece-il-logo.webp">
                  <cld-transformation height="90" width="500" crop="crop"/>
                </cld-image>
              </div> -->

              <vue-markdown>{{ $t('greece.corporate_intro') }}</vue-markdown>

              <template v-if="relatedEstates.length">
                <h4 class="padding-tb-15">{{ $t('greece.related_title') }}</h4>
                <v-row>
                  <v-col cols=12 lg=4 v-for="e in relatedEstates" :key="e.id">
                    <estate-box :estate="e"/>
                  </v-col>
                </v-row>
              </template>
            </v-col>

            <v-col>
              <quick-contact page="greece">
                <template v-slot:hidden-fields>
                  <input type="hidden" name="subject" value="greece contact">
                  <input type="hidden" name="message" value="greece contact">
                </template>
                <template v-slot:content>
                  <div class="text-center font-18 font-600">
                    <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
                    {{ $t("common.or") }}
                    <a :href="$config.whatsappPhone | whatsapp($t('greece.whatsapp_message'))" target="_blank">
                      {{ $t("quick_contact.or_whatsapp") }}
                      <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
                    </a>
                  </div>
                </template>
              </quick-contact>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import serviceCard from '@/common/components/services-cards/service-card'
import QuickContact from '@/common/components/quick-contact'
import HeaderParallax from '@/app/common/components/header-parallax'
import EstatesService from '@/app/modules/estates/estates-service'
import EstateBox from '@/app/modules/estates/components/estate-box'

export default {

  name: 'Mortgage',

  metaInfo () {
    return {
      title: 'Greece-Israel|יוון-ישראל',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.greece.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.greece.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.greece.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    serviceCard,
    QuickContact,
    HeaderParallax,
    EstateBox
  },

  data () {
    return {
      relatedEstates: [],
      cards: [
        {
          name: 'airbnb',
          title: this.$t('greece.cards.airbnb.title'),
          subtitle: this.$t('greece.cards.airbnb.subtitle'),
          image: 'https://img.icons8.com/color/160/null/airbnb.png'
        },
        {
          name: 'rent',
          title: this.$t('greece.cards.rent.title'),
          subtitle: this.$t('greece.cards.rent.subtitle'),
          image: 'https://img.icons8.com/office/160/null/key-exchange.png'
        },
        {
          name: 'improvement',
          title: this.$t('greece.cards.improvement.title'),
          subtitle: this.$t('greece.cards.improvement.subtitle'),
          image: 'https://img.icons8.com/external-filled-outline-wichaiwi/156/000000/external-home-improvement-services-business-filled-outline-wichaiwi.png'
        }
      ]
    }
  },

  methods: {
    async getRelatedEstates () {
      const query = {
        free_text: 'יוון',
        // free_text: 'נהריה', // for testing ui
        pagination: {
          page: 1,
          limit: 10
        }
      }
      let { results } = await EstatesService.searchEstates(query)
      results = results.sort(() => 0.5 - Math.random()).slice(0, 3)
      this.relatedEstates = results
    }
  },

  created () {
    this.getRelatedEstates()
  }
}
</script>

<style lang="scss">

  .greece-page {

    section {
      position: relative;
    }

    .badge {
      display: block;
      position: relative;
      max-width: 200px;
      font-size: $font-md;
      // font-weight: 600;
      text-align: center;
      transform: rotate(-10deg) translate(30px, -50px);
      font-family: 'Levin';
      color: var(--v-accent-darken4);

      &:before {
        content: '';
        position: absolute;
        top: -23%;
        left: -15%;
        width: 130%;
        height: 140%;
        background: url('~@/assets/images/brush.png') no-repeat;

        background-size: 100% 100%;
        z-index: -1;
      }

      @include xs-only {
        position: absolute;
        top: -20px;
        left: 50px;
        transform: rotate(-10deg) translate(15px, -20px) scale(0.8);

        &:before {
          background-image: url('~@/assets/images/brush-1.png');
        }
      }
    }

    &-corporate {
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    .v-card--disabled {
      padding-top: 10px;

      > *:not(.v-card__progress) {
        opacity: 1;
      }
    }

    form.quick-contact {
      position: sticky;
      top: 20px;
    }
  }

</style>
