<template>
  <div class="static-page work-with-us-page">

    <HeaderClean :header="$t('work_with_us.title')" :subheader="$t('work_with_us.subtitle')">
      <template>
        <spacer height="20px"/>
        <v-btn color="primary" @click="goToContact">להפוך למשווק נדל״ן בחברת סטרטאפ</v-btn>
      </template>
    </HeaderClean>

    <!-- top content -->
    <v-container class="text-content no-padding" fluid>
      <v-container tag="section" class="padding-tb-50 text-center">
        <h3 class="margin-tb-20 font-18">אצלנו כל אחד יכול להיות הבעלים ולהשתמש בזכות להפעיל חברת נדל״ן טכנולוגית. אנחנו רוצים להיות חלק מההצלחה שלך</h3>

        <p>Rbns חברת שיווק נדל״ן טכנולוגית המתרחבת וצומחת בכל יום, אנחנו מאמינים שלצד מנהלים מעולים המאמינים בעשייה עצמאית והתפתחות אישית ללא מעצורים אפשר להשפי הרבה מאוד טוב וליצור הצלחה קולקטיבית וגדילה פיננסית משמעותית אישית וכללית. אנחנו סטארט אפ טכנולוגי המורכב מהון אנושי גבוה ביותר ששם בחזית זירת הנדל״ן דגש על שיווק , טכנולוגיה וערכים של מסורת ושירות. הזכיינים שלנו מקבלים מוצר מצוין מעורר השתאות שיוצר שינוי יום יומי כללי לסביבה ובעיקר אישי אצלכם הזכיינים שלנו. אנחנו מספקים מעטפת טכנולוגית חדשנית בלעדית וייחודית. תמיכה וליווי מרגע ההצטרפות ולאורך כל הדרך.</p>
      </v-container>
    </v-container>

    <v-divider/>

    <!-- content -->
    <v-container class="no-padding" fluid>
      <v-container tag="section" class="padding-tb-50">
        <h3 class="text-center">חברת שיווק נדל״ן טכנולוגית עם מטרה ודרך למצוא את הנדל״ן שמגדיר את הלקוחות שלה בעולם</h3>

        <spacer height="50px"/>
        <TextImageRow v-for="(row, i) in textImageRows" :img="row.img" :title="row.title" :text="row.text" :reverse="i % 2 === 0" :key="i"/>
        <spacer height="20px"/>
      </v-container>
    </v-container>

    <v-divider/>

    <!-- video -->
    <v-container class="no-padding text-content" fluid>
      <v-container tag="section" class="padding-tb-50">
        <h3 class="text-center">תקשיבו למי שכבר איתנו</h3>
        <div class="work-with-us-page-video">
          <cld-video publicId="videos/rbns-work-inro" width="100%" controls="true"/>
        </div>
      </v-container>
    </v-container>

    <v-divider/>

    <!-- polygons -->
    <v-container fluid tag="section" class="">
      <h4 class="margin-tb-20 text-center">אז למה זה כדאי? ואיך זה עובד לכם ?</h4>

      <div class="polygons padding-bottom-20">
        <div class="polygon">
          <div>
            השאירו פרטים ליצירת קשר<!-- , סיפור אישי שלכם וקורות חיים -->
          </div>
        </div>
        <div class="polygon">
          <div>
            אנחנו ניצור עמכם קשר
          </div>
        </div>
        <div class="polygon">
          <div>
            נתאם פגישה
          </div>
        </div>
        <div class="polygon">
          <div>
            נבנה את תוכנית היציאה שלכם לשוק
          </div>
        </div>
        <div class="polygon">
          <div>
            מעטפת 360 של תמיכה וליווי לזכיין חדש
          </div>
        </div>
        <div class="polygon">
          <div>
            עזרה בגיוס והכשרת עובדים
          </div>
        </div>
        <div class="polygon">
          <div>
            מודל עסקי וטכנולוגיה ייחודית המעצימה את היכולות שלכם ומבדלת אתכם מייתר השוק
          </div>
        </div>
      </div>
    </v-container>

    <v-divider/>

    <v-container fluid class="padding-tb-50 text-content" ref="contact">
      <v-row class="align-center justify-center font-20" no-gutters>
        <v-col cols="12" sm="10">
          <p>אנחנו מחפשים את הטובים ביותר עם הלב הגדול ביותר</p>
          <QuickContact inline page="work_with_us">
            <template slot="hidden-fields">
              <input type="hidden" name="subject" value="עבודה">
              <input type="hidden" name="message" :value="message">
            </template>
            <template v-slot:content>
              <div class="text-center font-18 font-600">
                <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
                {{ $t("common.or") }}
                <a :href="'0774110137' | whatsapp($t('work_with_us.whatsapp_message', { message }))" target="_blank">
                  {{ $t("quick_contact.or_whatsapp") }}
                  <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
                </a>
              </div>
            </template>
          </QuickContact>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import QuickContact from '@/common/components/quick-contact'
import TextImageRow from '@/common/components/text-image-row'
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'workWithUs',

  metaInfo () {
    return {
      title: 'Work with us',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.work_with_us.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.work_with_us.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.work_with_us.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    TextImageRow,
    QuickContact,
    HeaderClean
  },

  data () {
    return {
      textImageRows: [
        {
          img: this.$cloudinary.url('/app_images/start-button.webp', { width: 450, crop: 'fit', background: 'rgb:0c0c0c' }),
          title: this.$t('work_with_us.text_images_rows.start.title')
        },
        {
          img: this.$cloudinary.url('/app_images/business-digital.webp', { width: 450, crop: 'fit' }),
          title: this.$t('work_with_us.text_images_rows.maximize_product.title')
        },
        {
          img: this.$cloudinary.url('/app_images/smartphone-home.webp', { height: 280, crop: 'crop', gravity: 'west', x: 100 }),
          title: this.$t('work_with_us.text_images_rows.tech.title')
        },
        {
          img: this.$cloudinary.url('/app_images/agent.webp', { width: 450, crop: 'fit' }),
          title: this.$t('work_with_us.text_images_rows.maximize_results.title')
        },
        {
          img: this.$cloudinary.url('/app_images/lead-up.webp', { width: 450, crop: 'fit' }),
          title: this.$t('work_with_us.text_images_rows.leading.title')
        }
      ],
      message: 'שלום, ברצוני לשמוע פרטים נוספים בנוגע להצטרפות ל-Rbns.'
    }
  },

  methods: {
    goToContact () {
      this.$vuetify.goTo(this.$refs.contact, { offset: 90 })
    }
  }
}
</script>

<style lang="scss">

  .work-with-us-page {
    position: relative;

    .text-content {
      background-color: #fff;
    }

    &-video {
      margin: 30px auto 0;
      max-width: 600px;
    }

    .polygons {
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      padding-left: 50px;
      font-size: $font-md;
    }

    .polygon {
      width: 250px;
      height: 200px;
      padding: 5px;
      background: var(--v-secondary-lighten1);
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      transform: skew(-14deg);

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 240px;
        height: 190px;
        padding: 0 30px;
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        background: var(--v-bright-lighten1);
        font-weight: 600;
      }

      &:nth-child(2) {
        margin: -101px -319px -100px 0px;
      }

      &:nth-child(3) {
        margin: -102px 416px -100px 0px;
      }

      &:nth-child(4) {
        margin: -2px 98px 0 0px;
      }

      &:nth-child(5) {
        margin: -102px -223px -100px 0px;
      }

      &:nth-child(6) {
        margin: -101px 514px -100px 0px;
      }

      &:nth-child(7) {
        margin: -2px 193px 0 0;
      }

      @include xs-only {

        &:nth-child(1) {
          margin-right: 190px;
        }

        &:nth-child(2) {
          margin: -102px -90px -100px 0px;
        }

        &:nth-child(3) {
          margin: 26px 190px -100px 0px;
        }

        &:nth-child(4) {
          margin: -1px -90px 0 0px;
        }

        &:nth-child(5) {
          margin: -75px 190px -100px 0px;
        }

        &:nth-child(6) {
          margin: 0 -90px -100px 0px;
        }

        &:nth-child(7) {
          margin: 25px 190px 0 0;
        }
      }
    }
  }

</style>
