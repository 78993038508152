<template>
  <div class="static-page cyprus-page">
    <header-parallax image="app_images/cyprus.webp" :options="{ crop: 'fill', y: 250, effect: 'brightness:-25'}"/>

    <v-container tag="section" class="padding-top-35">
      <v-row>
        <v-col>
          <section>
            <v-row>
              <v-col class="font-18">
                <h1 class="text-center font-36 padding-bottom-15">{{ $t('cyprus.title') }}</h1>
                <vue-markdown>{{ $t('cyprus.intro_text') }}</vue-markdown>
              </v-col>

              <!-- <v-col>
                <div class="img-shadow">
                  <cld-image publicId="app_images/cyprus/cyprus-view.webp">
                    <cld-transformation width="500" crop="fit"/>
                  </cld-image>
                </div>
              </v-col> -->
            </v-row>
          </section>

          <v-divider class="margin-top-20"/>

          <h4 class="font-24 padding-tb-30 text-center">
            {{ $t('cyprus.related_title') }}
            <v-btn icon color="primary darken-1" class=jump @click="scrollToContact"><v-icon class="icon-xl">mdi-chevron-down-circle-outline</v-icon></v-btn>
          </h4>

          <v-row no-gutters>
            <v-col>
              <div class=cyprus-page-grid>
                <div v-for="i in 7" :key="i">
                  <div class="img-shadow" @click="scrollToContact">
                    <cld-image :publicId="`app_images/cyprus/cyprus-${i}.webp`">
                      <cld-transformation width="800" crop="fit"/>
                    </cld-image>
                    <v-btn color="secondary" class="cyprus-page-grid-btn">{{ $t('cyprus.details_btn') }}</v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="margin-bottom-30">
            <v-col>
              <quick-contact page="cypros" inline id="contact" ref="contact_form">
                <template v-slot:hidden-fields>
                  <input type="hidden" name="subject" value="cypros contact">
                  <input type="hidden" name="message" value="cypros contact">
                </template>
                <template v-slot:content>
                  <div class="text-center font-18 font-600">
                    <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
                    {{ $t("common.or") }}
                    <a :href="$config.whatsappPhone | whatsapp($t('cyprus.whatsapp_message'))" target="_blank">
                      {{ $t("quick_contact.or_whatsapp") }}
                      <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
                    </a>
                  </div>
                </template>
              </quick-contact>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderParallax from '@/app/common/components/header-parallax'
import QuickContact from '@/common/components/quick-contact'
import EstatesService from '@/app/modules/estates/estates-service'
import VueScrollTo from 'vue-scrollto'

export default {

  name: 'Cyprus',

  // TODO: meta

  components: {
    QuickContact,
    HeaderParallax
  },

  data () {
    return {
      relatedEstates: []
    }
  },

  methods: {
    async getRelatedEstates () {
      const query = {
        free_text: 'קפריסין',
        // free_text: 'נהריה', // for testing ui
        pagination: {
          page: 1,
          limit: 15
        }
      }
      let { results } = await EstatesService.searchEstates(query)
      results = results.sort(() => 0.5 - Math.random()).slice(0, 3)
      this.relatedEstates = results
    },

    scrollToContact () {
      VueScrollTo.scrollTo('#contact')
      this.$refs.contact_form.$refs.first_name.focus()
    }
  },

  created () {
    this.getRelatedEstates()
  }
}
</script>

<style lang="scss" scoped>

  .cyprus-page {

    .v-icon {
      vertical-align: middle;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(10, 10%) 1fr;
      grid-template-rows: repeat(20, 40px);

      .img-shadow {
        border: 8px solid var(--v-bright-base);

        @include sm-and-up {
          border-width: 13px;
        }
      }

      &-btn {
        position: absolute;
        top: -1000px;
        left: -1000px;
        bottom: -1000px;
        right: -1000px;
        margin: auto;
        max-width: 175px;
        opacity: 0;
        transition: all .3s ease-in-out;
      }

      > div {
        cursor: pointer;
        transition: all .15s ease-in-out;

        &:nth-child(1) {
          grid-column: 1/span 7;
          grid-row: 1/span 4;
        }

        &:nth-child(2) {
          grid-column: 4/span 7;
          grid-row: 4/span 7;
        }

        &:nth-child(3) {
          grid-column: 1/span 6;
          grid-row: 7/span 6;
        }

        &:nth-child(4) {
          grid-column: 5/span 6;
          grid-row: 9/span 4;
        }

        &:nth-child(5) {
          grid-column: 1/span 7;
          grid-row: 11/span 4;
        }

        &:nth-child(6) {
          grid-column: 4/span 7;
          grid-row: 14/span 4;
        }

        &:nth-child(7) {
          grid-column: 1/span 10;
          grid-row: 17/span 5;
        }

        &:hover {
          z-index: 1;
          scale: 1.05;

          .cyprus-page-grid-btn {
            transition-delay: 1s;
            opacity: .75;
          }
        }
      }

      @include sm-only {
        grid-template-rows: repeat(23, 40px);

        > div {

          &:nth-child(1) {
            grid-column: 1/span 5;
            grid-row: 1/span 6;
          }

          &:nth-child(2) {
            grid-column: 5/span 6;
            grid-row: 2/span 7;
          }

          &:nth-child(3) {
            grid-column: 1/span 5;
            grid-row: 6/span 6;
          }

          &:nth-child(4) {
            grid-column: 5/span 6;
            grid-row: 10/span 7;
          }

          &:nth-child(5) {
            grid-column: 1/span 5;
            grid-row: 12/span 6;
          }

          &:nth-child(6) {
            grid-column: 5/span 6;
            grid-row: 16/span 7;
          }

          &:nth-child(7) {
            grid-column: 1/span 5;
            grid-row: 20/span 5;
          }
        }
      }

      @include md-only {
        grid-template-rows: repeat(25, 40px);

        > div {

          &:nth-child(1) {
            grid-column: 1/span 6;
            grid-row: 1/span 6;
          }

          &:nth-child(2) {
            grid-column: 5/span 6;
            grid-row: 3/span 8;
          }

          &:nth-child(3) {
            grid-column: 1/span 6;
            grid-row: 8/span 6;
          }

          &:nth-child(4) {
            grid-column: 6/span 5;
            grid-row: 11/span 8;
          }

          &:nth-child(5) {
            grid-column: 1/span 5;
            grid-row: 15/span 6;
          }

          &:nth-child(6) {
            grid-column: 5/span 6;
            grid-row: 18/span 6;
          }

          &:nth-child(7) {
            grid-column: 1/span 5;
            grid-row: 22/span 6;
          }
        }
      }

      @include lg-and-up {
        grid-template-rows: repeat(25, 40px);

        > div {

          &:nth-child(1) {
            grid-column: 1/span 5;
            grid-row: 1/span 9;
          }

          &:nth-child(2) {
            grid-column: 5/span 6;
            grid-row: 3/span 11;
          }

          &:nth-child(3) {
            grid-column: 1/span 5;
            grid-row: 8/span 8;
          }

          &:nth-child(4) {
            grid-column: 5/span 6;
            grid-row: 13/span 10;
          }

          &:nth-child(5) {
            grid-column: 1/span 5;
            grid-row: 15/span 7;
          }

          &:nth-child(6) {
            grid-column: 5/span 6;
            grid-row: 22/span 9;
          }

          &:nth-child(7) {
            grid-column: 1/span 5;
            grid-row: 23/span 7;
          }
        }
      }
    }
  }

</style>
