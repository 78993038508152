<template>
  <v-row wrap align="center">
    <v-col cols="12" sm="5" :offset-sm="reverse ? '0' : '1'" :order-sm="reverse ? '1' : '2'" :class="{reverse: reverse}">
      <div class="img-wrapper">
        <v-img alt="title" :height="$vuetify.breakpoint.smAndDown ? '175px' : '275px'" :src="img"/>
      </div>
    </v-col>

    <v-col cols="12" sm="6" :offset-sm="reverse ? '1' : '0'" :order-sm="reverse ? '2' : '1'">
      <div :class="{'margin-tb-50 text-center': $vuetify.breakpoint.smAndDown}">
        <h5 class="font-18" v-if="title">{{ title }}</h5>
        <vue-markdown v-if="text">{{ text }}</vue-markdown>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {

  name: 'TextImageRow',

  props: {
    img: String,
    title: String,
    text: String,
    reverse: Boolean
  },

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .img-wrapper {
    padding: 3px 1px 1px 3px;
    border: 2px solid #797878;
    border-radius: 42% 58%;

    .reverse & {
      border-radius: 58% 42%;
    }
  }

  .v-image {
    border-radius: 42% 58%;

    .reverse & {
      border-radius: 58% 42%;
    }
  }
</style>
