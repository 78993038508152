<template>
  <div class="static-page unsubscribe-page">
    <HeaderClean image="/app_images/house-space.webp" :header="$t('unsubscribe.title')" :options="{opacity: '70', background: 'black'}"/>

    <v-container class="text-center" v-if="!unsubscribed">
      <v-btn color="primary" :loading="loading" @click="unsubscribe">{{ $t('unsubscribe.button_text') }}</v-btn>
    </v-container>

    <v-snackbar
      :key="showSnackbar"
      :value="showSnackbar"
      transition="slide-y-reverse-transition"
      :color="snackbarColor"
      timeout="3000">

      <div class="font-16">
        {{snackbarMessage}}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'
import notificationsService from '@/app/common/services/notifications-service'

export default {

  name: 'Unsubscribe',

  components: {
    HeaderClean
  },

  data () {
    return {
      loading: false,
      showSnackbar: false,
      snackbarMessage: null,
      snackbarColor: null,
      unsubscribed: false
    }
  },

  methods: {
    async unsubscribe () {
      this.loading = true
      try {
        await notificationsService.deleteNotification(this.$route.params.id)
        this.snackbarColor = 'primary'
        this.snackbarMessage = this.$t('unsubscribe.success_message')
        this.showSnackbar = true
        this.unsubscribed = true
        setTimeout(() => {
          this.$router.push('/')
        }, 3200)
      } catch (e) {
        this.snackbarColor = 'error'
        this.snackbarMessage = this.$t('unsubscribe.error_message')
        this.showSnackbar = true
        this.loading = false
        setTimeout(() => {
          this.showSnackbar = false
        }, 3200)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 65px;
  }
</style>
