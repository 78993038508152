<template>

  <div class="contact-us">
    <HeaderClean :header="$t('contact.title')" :subheader="$t('contact.subtitle')"/>

    <v-container>

      <v-card>

        <v-row>

          <v-col cols=12 lg="7">
            <v-form ref="form" @submit.prevent="submit">
              <input type="hidden" name="source" :value="$route.name">
              <h5 class="font-18 mb-3">
                {{ $t('contact.send_a_message') }}
              </h5>

              <v-row class="no-padding-sides">

                <v-col class="no-padding-bottom" cols=12 md=4 sm=6>
                  <v-text-field v-model="formData.first_name"
                                :label="$t('contact.first_name_label')"
                                outlined
                                dense
                                :disabled="disableFirstName"
                                :rules="[rules.required]"/>
                </v-col>

                <v-col class="no-padding-bottom" cols=12 md=4 sm=6>
                  <v-text-field v-model="formData.last_name"
                                :label="$t('contact.last_name_label')"
                                outlined
                                dense
                                :disabled="disableLastName"
                                :rules="[rules.required]"/>
                </v-col>

                <v-col class="no-padding-bottom" cols=12 md=4>
                  <v-text-field v-model="formData.email"
                                :label="$t('contact.email_label')"
                                outlined
                                dense
                                :disabled="disableEmail"
                                :rules="[rules.required, rules.email]"/>
                </v-col>

              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-text-field v-model="formData.subject"
                                :label="$t('contact.subject_label')"
                                outlined
                                dense
                                :rules="[rules.required]"/>
                </v-col>
              </v-row>

              <v-row class="no-margin-top" no-gutters>
                <v-col>
                  <v-textarea v-model="formData.message"
                              :label="$t('contact.message_label')"
                              outlined
                              dense
                              :rules="[rules.required]"/>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" offset-sm="4" sm="4" md="4" lg="3">
                  <v-btn block color="secondary" type="submit" :disabled="btnDisabled">{{$t('contact.submit')}}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-divider :data-or="$t('common.or')" vertical v-if="$vuetify.breakpoint.lgAndUp"/>

          <v-col :class="['padding-sides-10', { flex: $vuetify.breakpoint.mdAndDown }]">
            <v-divider :data-or="$t('common.or')" v-if="$vuetify.breakpoint.mdAndDown"/>

            <h5 :class="['font-18 mb-3', {'text-center': $vuetify.breakpoint.mdAndDown}]">
              {{ $t('contact.other_channels') }}
            </h5>

            <v-row :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'" v-for="(contact, key) in contactItems" class="no-gutters" v-bind:key="key.toString()">
              <div>
                <a class="dark-link" :href="contact.href" target="_blank">
                  <v-row align="center" no-gutters>
                    <v-icon class="icon-md">{{contact.icon}}</v-icon>
                    <span class="font-16 font-600 padding-sides-10">{{contact.text}}</span>
                  </v-row>
                </a>
              </div>
            </v-row>

            <v-divider class="margin-tb-15"/>

            <h5 :class="['font-16', {'text-center': $vuetify.breakpoint.mdAndDown}]">{{ $t('contact.social_title') }}</h5>
            <v-row :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'" no-gutters class="margin-bottom-10">
              <social-button size="small" for="facebook"/>
              <social-button size="small" for="instagram"/>
            </v-row>
          </v-col>
        </v-row>

      </v-card>

    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      <div v-html="snackbarMessage"/>
    </v-snackbar>
  </div>
</template>

<script>
import rules from '@/common/mixins/validations-rules-mixin.js'
import { Api } from '@/common/services/api-service.js'
import SocialButton from '@/common/components/social-button'
import HeaderClean from '@/app/common/components/header-clean'
import { mapGetters } from 'vuex'

export default {

  name: 'ContactUs',

  metaInfo () {
    return {
      title: 'Contact us',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.contact_us.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.contact_us.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.contact_us.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    SocialButton,
    HeaderClean
  },

  data () {
    return {
      snackbar: false,
      parallaxHight: 500,
      snackbarMessage: '',
      snackbarColor: '',
      btnDisabled: false,
      contactItems: {
        email: {
          icon: 'mdi-email-outline',
          text: this.$config.supportEmail,
          href: `mailto:${this.$config.supportEmail}`
        },
        phone: {
          icon: 'mdi-phone',
          text: this.$config.shortPhone,
          href: `tel:${this.$config.shortPhone}`
        },
        whatsapp: {
          icon: 'mdi-whatsapp',
          text: this.$t('contact.whatsapp'),
          href: this.$options.filters.whatsapp(this.$config.whatsappPhone, '')
        }
      }
    }
  },

  mixins: [rules],

  computed: {
    ...mapGetters('user', ['user']),

    formData () {
      return {
        first_name: this.user ? this.user.first_name : '',
        last_name: this.user ? this.user.last_name : '',
        email: this.user ? this.user.email : '',
        subject: '',
        message: ''
      }
    },

    headerImgHeight () {
      return this.$vuetify.breakpoint.lgAndUp ? 350 : 300
    },

    disableEmail () {
      return !!this.user && !!this.user.email
    },

    disableFirstName () {
      return !!this.user && !!this.user.first_name
    },

    disableLastName () {
      return !!this.user && !!this.user.first_name
    }
  },

  methods: {
    showSnackbar (type) {
      this.snackbarMessage = this.$t(`contact.${type}_message`)
      this.snackbarColor = type
      this.snackbar = true
    },

    async submit () {
      if (this.$refs.form.validate()) {
        this.btnDisabled = true
        try {
          await Api.post('/contacts', this.formData)
          this.showSnackbar('success')
          setTimeout(() => {
            this.$router.push({ name: 'home', path: '*' })
          }, 5000)
        } catch (e) {
          console.error(e)
          this.showSnackbar('error')
          this.btnDisabled = false
        }

        this.$mixpanel.track('contact_form', { action: 'submit', page: this.$route.name })
      } else {
        return false
      }
    }
  }
}

</script>

<style lang="scss">

  .contact-us {

    .container {
      // opacity: .975;
      margin-bottom: 50px;

      @include sm-and-up {
        margin-top: -50px;
      }
    }

    .v-card {
      padding: 24px 24px 16px;
      color: var(--v-secondary-lighten3);
    }

    .v-divider {
      position: relative;
      margin: 0 20px;
      max-height: 328px; // TODO: find a better solution for ajustong the heihgt

      &:before {
        content: attr(data-or);
        position: absolute;
        top: calc(50% - 28px);
        right: -25px;
        line-height: 50px;
        width: 50px;
        font-size: $font-xl;
        text-align: center;
        background-color: #fff;
      }
    }

    @include md-and-down {

      .v-divider {
        margin: 20px;

        &:before {
          right: -1000px;
          left: -1000px;
          margin: 0 auto;
        }
      }
    }
  }
</style>
