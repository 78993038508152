<template>
  <div class="static-page estate-guide-page">
    <HeaderClean :header="$t('estate_guide.title')" :subheader="$t('estate_guide.subtitle')"/>

    <v-container tag="section" class="padding-top-35">

      <section class="estate-guide-page-section-chips">
        <h3 class="text-center">{{ $t('estate_guide.chips_section_title') }}</h3>
        <div class="estate-guide-page-chips">
          <v-chip label color="accent" v-for="chip in $t('estate_guide.chips_section_chips')" :key="chip">
            <strong>{{ chip }}</strong>
          </v-chip>
        </div>
      </section>

      <section>
        <h3 class="text-center">{{ $t('estate_guide.ad_section_title') }}</h3>
        <div class="estate-guide-page-chips">
          <v-btn color="primary" @click="goToForm">{{ $t('estate_guide.ad_section_button') }}</v-btn>
        </div>
      </section>

      <v-row class="padding-tb-30">
        <template v-if="user">
          <v-col>
            <h2>{{ $t('estate_guide.intro_title_user') }}</h2>
            <vue-markdown :key="!!user">{{ $t('estate_guide.intro_text_user') }}</vue-markdown>

            <v-btn dark :to="{name: 'new_estate'}" class="add-estate">
              <span class="font-13">{{$t('main_header.add_property')}}</span>
              <spacer width="10px"/>
              <v-icon class="icon-sm">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </template>
        <v-col v-else>
          <h2>{{ $t('estate_guide.intro_title') }}</h2>
          <vue-markdown :key="user">{{ $t('estate_guide.intro_text') }}</vue-markdown>
          <signup class="signup-container" ref="signupForm" id="signup_form"/>
        </v-col>
        <v-col cols=12 sm=7 offset-sm=0 offset-md=1>
          <cld-video muted="true" playsinline autoplay publicId="marketing_videos/v-tour-1" width="100%" :controls="controls"/>
        </v-col>
      </v-row>

      <section class="estate-guide-page-section-worth">
        <h3 class="text-center padding-20">{{ $t('estate_guide.worth_section_title') }}</h3>
        <v-row>
          <v-col cols="6" sm="3" v-for="card in $t('estate_guide.worth_section_cards')" :key="card.title" class="text-center">
            <v-card>
              <v-card-text>
                <h5 class="font-13">{{ card.title }}</h5>
                {{ card.text }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <section class="estate-guide-page-section-give">
        <h3 class="text-center padding-20">{{ $t('estate_guide.give_section_title') }}</h3>
        <v-card>
          <v-row class="padding-30">
            <v-col cols="6" sm="3" v-for="card in $t('estate_guide.give_section_cards')" :key="card.title" class="text-center">
              <v-card>
                <v-card-text>
                  <v-icon class="icon-3xl">{{ card.icon }}</v-icon>
                  <h5 class="font-15 margin-top-10">{{ card.title }}</h5>
                  {{ card.text }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters class="padding-30">
            <v-col>
              <h5 class="font-18 padding-bottom-10">{{ $t('estate_guide.give_section_subtitle') }}</h5>
              <vue-markdown>{{ $t('estate_guide.give_section_text') }}</vue-markdown>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-center">
              <v-btn color="primary" @click="goToForm">{{ $t('estate_guide.ad_section_button') }}</v-btn>
            </v-col>
          </v-row>
          <spacer height="20px"/>
        </v-card>

      </section>

      <spacer height="20px"/>
      <v-divider/>
      <spacer height="20px"/>

      <v-expansion-panels multiple role="list">
        <v-expansion-panel class="v-expansion-panel--active" v-for="q in $t('estate_guide.questions')" :key="q.q" role="listitem">
          <v-expansion-panel-header class="font-18 font-600" role="term">
            {{q.q}}
          </v-expansion-panel-header>

          <v-expansion-panel-content role="definition">
            <vue-markdown>{{q.a}}</vue-markdown>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <spacer height="20px"/>

      <!-- magazine -->
      <!-- <v-row>
        <v-col cols=12 sm="4" v-for="art in articles" :key="art.id">
          <article-box :article="art" image-width="250" small/>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'
// import ArticleBox from '@/app/modules/magazine/components/article-box'
// import MagazineService from '@/app/modules/magazine/magazine-service'
import Signup from '@/app/modules/user/components/signup-form'
import { mapGetters } from 'vuex'
import VueScrollTo from 'vue-scrollto'

export default {

  name: 'EstateGuide',

  // TODO: meta

  components: {
    HeaderClean,
    // ArticleBox,
    Signup
  },

  data () {
    return {
      // articles: [],
      controls: false,
      chips: []
    }
  },

  computed: {
    ...mapGetters('user', ['user'])
  },

  methods: {
    goToForm () {
      this.$refs.signupForm.$refs.form.$el['firstName'].focus()
      VueScrollTo.scrollTo('#signup_form', { offset: -75 })
    }
  },

  async created () {
    // this.articles = await MagazineService.getArticles(1, 3)
    setTimeout(() => {
      this.controls = true
    }, 22000)
  },

  mounted () {
    console.log(this.$refs.signupForm.$refs.form.$el['firstName'])
  }
}
</script>

<style lang="scss" scoped>

  .estate-guide-page {

    &-chips {
      display: flex;
      flex-flow: wrap;
      gap: 15px;
      justify-content: center;
      padding: 20px 0;
    }

    .signup-container {
      padding: 12px;
      border: 1px solid var(--v-bright-darken4);
      border-radius: 4px;
    }

      &-section-give,
      &-section-worth {

        .row {

          .v-card {
            min-height: 100%;
          }
        }
      }
  }
</style>
