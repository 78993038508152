<template>
  <div class="static-page cookies-page">
    <header-clean :header="$t('cookies.title')"/>

    <v-container class="padding-tb-50">
      <v-expansion-panels multiple role="list">
        <v-expansion-panel class="v-expansion-panel--active" v-for="topic in $t('cookies.content')" :key="topic.title" role="listitem">

          <v-expansion-panel-header class="font-18 font-600" role="term">
            {{topic.title}}
          </v-expansion-panel-header>

          <v-expansion-panel-content role="definition">
            <vue-markdown :anchorAttributes="{target:'_blank', rel:'nofolow'}">{{topic.content}}</vue-markdown>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'CookiesPolicy',

  metaInfo () {
    return {
      title: 'Cookies',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.cookies.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.cookies.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.cookies.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    HeaderClean
  }
}
</script>

<!--
<style lang="scss">
</style>
-->
