<template>
  <div class="static-page mortgage-page">
    <HeaderClean :header="$t('mortgage.title')"/>

    <Spacer height="20px"/>

    <v-container tag="section">
      <v-row>
        <v-col md=8 lg=9 cols=10 offset=1 offset-md=0>
          <v-row>
            <v-col order-sm="2">
              <strong class="badge">{{ $t('mortgage.badge') }}</strong>
            </v-col>

            <v-col cols=12 sm=8 order-sm="1">
              <h2>{{ $t('mortgage.intro_title') }}</h2>
              <vue-markdown>{{ $t('mortgage.intro_text') }}</vue-markdown>
            </v-col>
          </v-row>

          <aside>
            <servicesCards :md="6" :services="['sale', 'buy']" v-if="$vuetify.breakpoint.mdAndUp"/>
          </aside>
        </v-col>

        <v-col cols=10 offset=1 md=4 lg=3 offset-md=0>
          <quick-contact page="mortgage">
            <template v-slot:hidden-fields>
              <input type="hidden" name="subject" value="mortgage contact">
              <input type="hidden" name="message" value="mortgage contact">
            </template>
            <template v-slot:content>
              <div class="text-center font-18 font-600">
                <vue-markdown>{{$t("quick_contact.by_phone")}}</vue-markdown>
                {{ $t("common.or") }}
                <a :href="$config.whatsappPhone | whatsapp($t('mortgage.whatsapp_message'))" target="_blank">
                  {{ $t("quick_contact.or_whatsapp") }}
                  <v-icon class="icon-lg" color="var(--v-whatsapp)">mdi-whatsapp</v-icon>
                </a>
              </div>
            </template>
          </quick-contact>
        </v-col>
      </v-row>
    </v-container>

    <v-container tag="aside" v-if="$vuetify.breakpoint.smAndDown">
      <v-col cols=10 offset="1" md=4 offset-md="0">
        <h4>היכולות שלנו השקט שלכם</h4>
        <servicesCards :services="['sale', 'buy']"/>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import servicesCards from '@/common/components/services-cards/services-cards'
import QuickContact from '@/common/components/quick-contact'
import HeaderClean from '@/app/common/components/header-clean'

export default {

  name: 'Mortgage',

  metaInfo () {
    return {
      title: 'Mortgage',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.mortgage.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.mortgage.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.mortgage.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    servicesCards,
    QuickContact,
    HeaderClean
  },

  data () {
    return {
    }
  }
}
</script>

<style lang="scss">

  .mortgage-page {

    section {
      position: relative;
    }

    .badge {
      display: block;
      position: relative;
      max-width: 200px;
      font-size: $font-md;
      // font-weight: 600;
      text-align: center;
      transform: rotate(-10deg) translate(30px, -50px);
      font-family: 'Levin';
      color: var(--v-accent-darken4);

      &:before {
        content: '';
        position: absolute;
        top: -23%;
        left: -15%;
        width: 130%;
        height: 140%;
        background: url('~@/assets/images/brush.png') no-repeat;

        background-size: 100% 100%;
        z-index: -1;
      }

      @include xs-only {
        position: absolute;
        top: -20px;
        left: 50px;
        transform: rotate(-10deg) translate(15px, -20px) scale(0.8);

        &:before {
          background-image: url('~@/assets/images/brush-1.png');
        }
      }
    }
  }

</style>
