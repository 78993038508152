<template>
  <div class="static-page about-page">

    <HeaderClean :header="$t('about.header')" :subheader="$t('about.subheader')"/>

    <v-container tag="section" :fluid="$vuetify.breakpoint.xsOnly" class="margin-top-50">
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="8">
          <vue-markdown class="content-header"> {{$t('about.intro')}} </vue-markdown>
        </v-col>
      </v-row>
    </v-container>

    <v-container tag="section" :fluid="$vuetify.breakpoint.xsOnly">
      <div :class="['padding-tb-50', {'margin-sides-50': $vuetify.breakpoint.smOnly}]">
        <TextImageRow v-for="(row, i) in $t('about.text_images_rows')"
          :img="$cloudinary.url(`/app_images/${textImageRowsImages[i]}.webp`, { width: 450, crop: 'fit' })"
          :title="row.title"
          :text="row.text"
          :reverse="i % 2 !== 0"
          :key="i"/>
      </div>

      <div class="text-center font-20">
        <vue-markdown>{{$t('about.footer_header')}}</vue-markdown>
      </div>

      <section class="padding-30">
        <quick-contact inline>
          <template v-slot:hidden-fields>
            <input type="hidden" name="subject" value="about contact">
          </template>
          <template v-slot:custom-fields>
            <v-textarea name="message" dense outlined auto-grow rows="2" :label="$t('contact.message_label')"/>
          </template>
        </quick-contact>
      </section>
    </v-container>
  </div>
</template>

<script>
import TextImageRow from '@/common/components/text-image-row'
import HeaderClean from '@/app/common/components/header-clean'
import QuickContact from '@/common/components/quick-contact'
import rules from '@/common/mixins/validations-rules-mixin.js'

export default {
  name: 'About',

  metaInfo () {
    return {
      title: 'About',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.about.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.about.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.about.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    TextImageRow,
    HeaderClean,
    QuickContact
  },

  mixins: [rules],

  data () {
    return {
      textImageRowsImages: ['business-meeting', 'agent', 'men', 'social-media', 'about-arbns-tech']
    }
  }
}
</script>

<style lang="scss">

  .about-page {

    .content-header {
      text-align: center;
      font-size: $font-md;
      font-weight: 900;
    }
  }

</style>
